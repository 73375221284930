<template>
  <div class="selectBar" tabindex="0" @blur="activeSelect = false">
    <div class="selectBar__selected" @click="activeSelect = true">
      {{ selected.label }}
      <img src="@/assets/icon_downward.svg" alt />
    </div>
    <v-slide-y-reverse-transition>
      <div class="selectBar__list" v-show="activeSelect">
        <div
          class="selectBar__list__item"
          :class="{ active: !value }"
          @click="clearHandler()"
        >
          請選擇
        </div>
        <div
          v-for="item in items"
          :key="item.value"
          class="selectBar__list__item"
          :class="{
            active: item.value === value,
            disable: item.value === disableItemValue,
          }"
          @click="selectHandler(item)"
        >
          {{ item.label }}
        </div>
      </div>
    </v-slide-y-reverse-transition>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [String, Number],
      default: "",
    },
    filterItemValue: {
      type: [String, Number],
      default: "",
    },
    disableItemValue: {
      type: [String, Number],
      default: "",
    },
  },
  emit: ["change"],
  data() {
    return {
      activeSelect: false,
    };
  },
  computed: {
    selected() {
      let obj = this.items.find((item) => item.value === this.value);
      return obj ? obj : { label: "請選擇", value: "" };
    },
  },
  methods: {
    selectHandler(item) {
      this.$emit("change", item.value);
      this.activeSelect = false;
    },
    clearHandler() {
      this.$emit("change", "");
      this.activeSelect = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixin.scss";
.selectBar {
  position: relative;
  font-size: 18px;
  line-height: 26px;
  color: #9b9b9b;
  &__selected {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f0f2f4;
    box-shadow: 2px 2px 4px #ffffff, inset 2px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    padding: 7px 16px;
    color: $secondary-color-1;
    cursor: pointer;
    & > img {
      width: 18px;
    }
  }
  &__list {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    z-index: 5;
    background: #f0f2f4;
    box-shadow: 2px 2px 4px #ffffff, inset 2px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    padding-bottom: 16px;
    overflow: hidden;
    &__item {
      padding: 7px 16px;
      cursor: pointer;
      &:hover {
        background: #d9d9d9;
      }
      &.active {
        color: $secondary-color-1;
      }
      &.disable {
        color: #d2d2d2;
        cursor:default;
        pointer-events: none;
      }
    }
  }
}
</style>