var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: "fade" } },
    [
      _c("FixedPopup", [
        _c(
          "div",
          { staticClass: "friendCreateDialog" },
          [
            _c("FriendCreate", {
              on: { success: _vm.success, cancel: _vm.cancel }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }