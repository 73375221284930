<template>
  <!-- 開發中 -->
  <transition name="fade">
    <FixedPopup>
      <div class="friendCreateDialog">
        <FriendCreate @success="success" @cancel="cancel" />
      </div>
    </FixedPopup>
  </transition>
</template>

<script>
import FixedPopup from "@/components/Fixed_popup.vue";
import FriendCreate from "@/components/好友相關/FriendCreate.vue";
import Vue from "vue";

export default {
  components: { FixedPopup, FriendCreate },
  data() {
    return {};
  },
  created() {},
  methods: {
    success() {
      this.$emit("success");
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixin.scss";

.friendCreateDialog {
  // margin-top: 10vh;
  // padding-top: 20vh;
  @include mobile {
    padding: 12px;
    background-color: $theme-color-3;
    border-radius: 50px;
    box-shadow: -1px -1px 4px 0 #ffffff, -1px -1px 4px 0 #ffffff,
      2px 2px 6px 0 rgba(0, 0, 0, 0.25);
  }
}
</style>
