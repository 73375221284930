<template>
  <!-- 開發中 -->
  <transition name="fade">
    <FixedPopup>
      <div class="selectFriendsDialog">
        <div class="selectFriendsDialog__title">選擇星盤好友</div>
        <div class="selectFriendsDialog__filter">
          <label>
            <img class="serachIcon" src="@/assets/占星小鋪/search.svg" alt="" />
            <input
              type="text"
              placeholder="搜尋星盤名稱"
              v-model="searchKey_temp"
              @keyup.enter="filterHandler"
            />
          </label>
          <button class="filter_btn" @click="filterHandler">搜尋</button>
        </div>
        <div class="selectFriendsDialog__list" v-if="list_filter.length > 0">
          <div
            class="selectAstrolabeItem"
            v-for="item in list_filter"
            :key="item.AstrolabeFileId"
          >
            <div class="selectAstrolabeItem__pic">
              <img :src="item.ProfilePhotoSrc" alt="" />
            </div>
            <div class="selectAstrolabeItem__info">
              <div class="selectAstrolabeItem__info__name">{{ item.Name }}</div>
              <div class="selectAstrolabeItem__info__birthday">
                {{ item.BirthDate }}
              </div>
            </div>
            <div
              class="selectAstrolabeItem__btn"
              :class="{ active: item.is_checked }"
              @click="selectHandler(item)"
            >
              <img
                src="@/assets/占星小鋪/selectActive.svg"
                v-if="item.is_checked"
              />
              <img src="@/assets/占星小鋪/select.svg" v-else alt="" />
            </div>
          </div>
        </div>
        <div class="selectFriendsDialog__empty" v-else>
          <div>無符合條件的星盤</div>
        </div>
        <div class="selectFriendsDialog__closeBtn">
          <img src="@/assets/月亮日記/close.svg" alt="" @click="cancel()" />
        </div>
      </div>
    </FixedPopup>
  </transition>
</template>

<script>
import FixedPopup from "@/components/Fixed_popup.vue";

export default {
  components: { FixedPopup },
  props: {
    friendsList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      searchKey_temp: "",
      searchKey: "",
    };
  },
  computed: {
    list_filter() {
      return this.friendsList.filter((item) => {
        return item.Name.includes(this.searchKey);
      });
    },
  },
  methods: {
    filterHandler() {
      this.searchKey = this.searchKey_temp;
    },
    cancel() {
      this.$emit("cancel");
      this.searchKey = "";
    },
    selectHandler(item) {

      this.$emit("toSelect", { item, is_checked: item.is_checked });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixin.scss";

.selectFriendsDialog {
  z-index: 1050;
  margin: auto;
  @include rect(520px, auto, $theme-color-3, 50px);
  @include center;
  flex-direction: column;
  padding: 36px;
  transition: 0.5s;
  position: relative;
  @include mobile() {
    @include rect(326px, auto, $theme-color-3, 50px);
    margin: 0 24px;
    padding: 32px 28px;
  }
  &__title {
    color: $secondary-color-1;
    font-weight: 700;
    font-size: 24px;
    text-align: center;
  }
  &__filter {
    margin-top: 36px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    @include mobile {
      margin-top: 24px;
    }
    label {
      flex-grow: 1;
      position: relative;
      .serachIcon {
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
      }
      input,
      input:focus {
        width: 100%;
        height: 36px;
        background: #f0f2f4;
        box-shadow: 2px 2px 4px #ffffff, inset 2px 2px 4px rgba(0, 0, 0, 0.15);
        border-radius: 25px;
        outline: none;
        padding: 8px 46px;
        @include mobile {
          max-width: 179px;
          height: 36px;
        }
      }
      input::placeholder {
        font-size: 14px;
        line-height: 20px;
        color: #d9d9d9;
      }
    }
    .filter_btn {
      @include btn-default(112px, 36px);
      border-radius: 100px;
      @include mobile {
        @include btn-default(72px, 36px);
      }
    }
  }
  &__list {
    margin-top: 34px;
    margin-right: -20px;
    padding-right: 20px;
    width: calc(100% + 20px);
    max-height: 458px;
    overflow-y: auto;
    overflow-y: overlay;
    @include scroll-primary;
  }
  &__empty {
    margin-top: 34px;
    font-size: 18px;
    line-height: 26px;
    color: #9b9b9b;
    text-align: center;
  }
  &__closeBtn {
    position: absolute;
    top: 12px;
    right: 16px;
    cursor: pointer;
    @include mobile {
      top: 16px;
      right: 12px;
    }
    img {
      width: 40px;
      height: 40px;
      @include mobile {
        width: 36px;
        height: 36px;
      }
    }
  }
}

.selectAstrolabeItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 36px;
  &:last-child {
    margin-bottom: 0;
  }
  &__pic {
    width: 80px;
    height: 80px;
    padding: 8px;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: -1px -1px 4px #ffffff, -1px -1px 4px #ffffff,
      2px 2px 6px rgba(0, 0, 0, 0.25);
    img {
      width: 100%;
    }
  }
  &__info {
    flex-grow: 1;
    margin-left: 20px;
    &__name {
      font-size: 18px;
      line-height: 36px;
      font-weight: 700;
      color: #767676;
    }
    &__birthday {
      font-size: 14px;
      line-height: 28px;
      color: #9b9b9b;
    }
  }
  &__btn {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #f0f2f4;
    box-shadow: -1px -1px 4px #ffffff, -1px -1px 4px #ffffff,
      2px 2px 6px rgba(0, 0, 0, 0.25);
    display: flex;
    cursor: pointer;
    &.active {
      background: #798da5;
      box-shadow: 2.25px 2.25px 4.5px #ffffff,
        inset 4.5px 4.5px 11.25px rgba(0, 0, 0, 0.08);
    }
    @include mobile {
      top: 18px;
      right: 20px;
      width: 28px;
      height: 28px;
      margin-left: 8px;
    }
    img {
      margin: auto;
      width: 20px;
    }
  }
}
</style>
