var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "selectBar",
      attrs: { tabindex: "0" },
      on: {
        blur: function($event) {
          _vm.activeSelect = false
        }
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "selectBar__selected",
          on: {
            click: function($event) {
              _vm.activeSelect = true
            }
          }
        },
        [
          _vm._v("\n    " + _vm._s(_vm.selected.label) + "\n    "),
          _c("img", {
            attrs: { src: require("@/assets/icon_downward.svg"), alt: "" }
          })
        ]
      ),
      _c("v-slide-y-reverse-transition", [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeSelect,
                expression: "activeSelect"
              }
            ],
            staticClass: "selectBar__list"
          },
          [
            _c(
              "div",
              {
                staticClass: "selectBar__list__item",
                class: { active: !_vm.value },
                on: {
                  click: function($event) {
                    return _vm.clearHandler()
                  }
                }
              },
              [_vm._v("\n        請選擇\n      ")]
            ),
            _vm._l(_vm.items, function(item) {
              return _c(
                "div",
                {
                  key: item.value,
                  staticClass: "selectBar__list__item",
                  class: {
                    active: item.value === _vm.value,
                    disable: item.value === _vm.disableItemValue
                  },
                  on: {
                    click: function($event) {
                      return _vm.selectHandler(item)
                    }
                  }
                },
                [_vm._v("\n        " + _vm._s(item.label) + "\n      ")]
              )
            })
          ],
          2
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }